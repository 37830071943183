.day-container {
    box-sizing: border-box;
    position: relative;
    width: fit-content;
    height: min-content;
}
.day-container__selected {
    color: #fff;
}

.day-container__hovered button {
    color: #fff;
    background-color: #8ab528 !important;
}

.day-container__selected button {
    background-color: #8ab528 !important;
}

.day-container:hover button {
    transition: none;
    background-color: #8ab528 !important;
    color: #fff;
}

.day-container:hover button {
    color: #fff;
    background-color: #8ab528 !important;
}

.day-container__range {
    background-color: #e1e4e8;
}

/* .day-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
} */

/* .day-container__selected:hover .day-background__selected {
}

.day-background__hovered {
  position: absolute;
  z-index: -1;
  width: 50%;
  height: 100%;
  background-color: #e1e4e8;
  left: 0;
}

.day-background__selected {
  position: absolute;
  z-index: -1;
  width: 50%;
  height: 100%;
  background-color: #e1e4e8;
}

.day-background__first {
  right: 0;
  width: 50%;
}

.day-background__last {
  left: 0;
  width: 50%;
}

.day-background__last.day-background__reversed {
  left: 50%;
  right: 0;
} */
.day-container__selected--start .day-background {
    display: none !important;
}

.day-background {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: #e1e4e8;
    display: none;
}

.day-background.start {
    right: 0;
    display: block;
}

.day-background.end {
    left: 0;
    display: block;
}
