.select-button {
    width: 45px;
    height: 24px;
    background-color: #f0f0f0;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.select-button__thumb {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(120%, -50%);
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #8ab528;
    transition: all 0.3s ease;
}

.select-button__thumb--blocked {
    transform: translate(20%, -50%);
    background-color: #eb5757;
}

.select-button__thumb--loading {
    background-color: #b8b8b8;
}

.select-button__thumb--loading > .select-button__thumb {
    background-color: #757575;
}

.loader {
    width: 100%;
    height: 100%;
    animation: wave 1s linear infinite;
    background: rgb(255, 255, 255);
    opacity: 0.5;
    z-index: 11;
    overflow: hidden;
}

@keyframes wave {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(100%);
    }
}
