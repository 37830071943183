.custom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.07vw;
    width: 23.333vw;
    height: 3.125vw;
    background-color: #fff;
    border: 1px solid #181818;
    color: #000;
    margin-top: 1.15vw;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.1s ease;
}

.custom-button:disabled {
    border-color: #bdbdbd;
    color: #bdbdbd;
}

.custom-button:hover {
    background-color: #181818;
    color: #fff;
}

.custom-button--success {
    background-color: #8ab528;
    color: #fff;
    border: none;
}

.custom-button--success:disabled {
    background-color: #8ab528;
    background-color: #bdbdbd;
    color: #fff;
}
