.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'IBM Plex Sans';
    box-sizing: border-box;
    background-color: #fff;
    padding: 5.208vw 3.75vw;
    outline: none;
}

.block-user__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.938vw;
}

.block-user__icon {
    padding-bottom: 2.604vw;
}

.block-user__error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #eb5757;
    font-size: 1.25vw;
    padding-bottom: 15px;
    font-weight: 500;
    line-height: 31.2px;
}

.block-user__error-icon {
    padding-bottom: 0.781vw;
}

.block-user__error-message-text {
}

.block-user__description {
    padding-bottom: 0.781vw;
    line-height: 27px;
    width: 28.542vw;
}

.block-user__description-text {
}

.block-user__contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.block-user__contacts-header {
    font-weight: 500;
    padding-bottom: 0.781vw;
}

.block-user__contacts-text {
    line-height: 27px;
}
